import '@angular/compiler';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PortalCommunityViewComponent } from './portal.community.view/portal.community.view.component';
import { PortalDizestStatuscardComponent } from './portal.dizest.statuscard/portal.dizest.statuscard.component';
import { PortalKreonetadmInstituteTicketingComponent } from './portal.kreonetadm.institute.ticketing/portal.kreonetadm.institute.ticketing.component';
import { PortalDizestEditorImageComponent } from './portal.dizest.editor.image/portal.dizest.editor.image.component';
import { PortalKreonetadmAvailabilityComponent } from './portal.kreonetadm.availability/portal.kreonetadm.availability.component';
import { PortalKreonetadmBoardListComponent } from './portal.kreonetadm.board.list/portal.kreonetadm.board.list.component';
import { PortalKreonetPlaceholderUserComponent } from './portal.kreonet.placeholder.user/portal.kreonet.placeholder.user.component';
import { PortalKreonetadmWidgetInstituteInfoComponent } from './portal.kreonetadm.widget.institute.info/portal.kreonetadm.widget.institute.info.component';
import { PortalDizestEditorUimodeComponent } from './portal.dizest.editor.uimode/portal.dizest.editor.uimode.component';
import { PortalDizestSidebarTimerComponent } from './portal.dizest.sidebar.timer/portal.dizest.sidebar.timer.component';
import { PortalDizestDriveComponent } from './portal.dizest.drive/portal.dizest.drive.component';
import { PortalKreonetadmDevicesComponent } from './portal.kreonetadm.devices/portal.kreonetadm.devices.component';
import { PortalDizestEditorSettingComponent } from './portal.dizest.editor.setting/portal.dizest.editor.setting.component';
import { ComponentNavMypageComponent } from './component.nav.mypage/component.nav.mypage.component';
import { PortalKreonetadmInstituteItemComponent } from './portal.kreonetadm.institute.item/portal.kreonetadm.institute.item.component';
import { PortalKreonetadmBoardItemComponent } from './portal.kreonetadm.board.item/portal.kreonetadm.board.item.component';
import { PortalDizestSidebarPackagesComponent } from './portal.dizest.sidebar.packages/portal.dizest.sidebar.packages.component';
import { LayoutLayoutMypageComponent } from './layout.layout.mypage/layout.layout.mypage.component';
import { PortalDizestAppsComponent } from './portal.dizest.apps/portal.dizest.apps.component';
import { PortalDizestEditorVideoComponent } from './portal.dizest.editor.video/portal.dizest.editor.video.component';
import { PortalWorksProjectInfoComponent } from './portal.works.project.info/portal.works.project.info.component';
import { PortalApplyAdminReportComponent } from './portal.apply.admin.report/portal.apply.admin.report.component';
import { PortalApplyAdminNoticesComponent } from './portal.apply.admin.notices/portal.apply.admin.notices.component';
import { PageAdminSystemComponent } from './page.admin.system/page.admin.system.component';
import { PageApplyNotiComponent } from './page.apply.noti/page.apply.noti.component';
import { PortalApplyAdminReportDetailComponent } from './portal.apply.admin.report.detail/portal.apply.admin.report.detail.component';
import { PortalDizestEditorTerminalComponent } from './portal.dizest.editor.terminal/portal.dizest.editor.terminal.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalWorksProjectDriveComponent } from './portal.works.project.drive/portal.works.project.drive.component';
import { PortalApplyListApplicationComponent } from './portal.apply.list.application/portal.apply.list.application.component';
import { PortalApplyAdminListDetailComponent } from './portal.apply.admin.list.detail/portal.apply.admin.list.detail.component';
import { PortalKreonetadmDizestComponent } from './portal.kreonetadm.dizest/portal.kreonetadm.dizest.component';
import { PortalCommunityCommentComponent } from './portal.community.comment/portal.community.comment.component';
import { PortalWorksProjectIssueboardComponent } from './portal.works.project.issueboard/portal.works.project.issueboard.component';
import { PortalApplyAdminListComponent } from './portal.apply.admin.list/portal.apply.admin.list.component';
import { PortalWorksProjectIssueboardIssueComponent } from './portal.works.project.issueboard.issue/portal.works.project.issueboard.issue.component';
import { PortalKreonetGraphPieComponent } from './portal.kreonet.graph.pie/portal.kreonet.graph.pie.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalDizestCodeflowAppinfoComponent } from './portal.dizest.codeflow.appinfo/portal.dizest.codeflow.appinfo.component';
import { PortalKreonetPopupDevicesComponent } from './portal.kreonet.popup.devices/portal.kreonet.popup.devices.component';
import { PortalWorksWidgetProjectIssueboardIssueComponent } from './portal.works.widget.project.issueboard.issue/portal.works.widget.project.issueboard.issue.component';
import { PortalWorksProjectPlanComponent } from './portal.works.project.plan/portal.works.project.plan.component';
import { PageInstitutesComponent } from './page.institutes/page.institutes.component';
import { PortalDizestEditorNotsupportedComponent } from './portal.dizest.editor.notsupported/portal.dizest.editor.notsupported.component';
import { PortalDizestHealthComponent } from './portal.dizest.health/portal.dizest.health.component';
import { PagePageAdminInfoComponent } from './page.page.admin.info/page.page.admin.info.component';
import { PageNoticeComponent } from './page.notice/page.notice.component';
import { ComponentCommentComponent } from './component.comment/component.comment.component';
import { PortalKreonetWidgetWeathermapComponent } from './portal.kreonet.widget.weathermap/portal.kreonet.widget.weathermap.component';
import { PageInstitutesDocsComponent } from './page.institutes.docs/page.institutes.docs.component';
import { ComponentInstitutesEventSubmitComponent } from './component.institutes.event.submit/component.institutes.event.submit.component';
import { PortalDizestCodeflowNoteComponent } from './portal.dizest.codeflow.note/portal.dizest.codeflow.note.component';
import { PageMydeskMypageComponent } from './page.mydesk.mypage/page.mydesk.mypage.component';
import { PortalKreonetPlaceholderCardComponent } from './portal.kreonet.placeholder.card/portal.kreonet.placeholder.card.component';
import { ComponentLoadingComponent } from './component.loading/component.loading.component';
import { PortalKreonetWidgetAvailabilityComponent } from './portal.kreonet.widget.availability/portal.kreonet.widget.availability.component';
import { PortalKreonetadmWeathermapComponent } from './portal.kreonetadm.weathermap/portal.kreonetadm.weathermap.component';
import { PortalKreonetadmNewComponent } from './portal.kreonetadm.new/portal.kreonetadm.new.component';
import { PortalKreonetadmWidgetInstituteDocsComponent } from './portal.kreonetadm.widget.institute.docs/portal.kreonetadm.widget.institute.docs.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalApplyAdminNoticeItemComponent } from './portal.apply.admin.notice.item/portal.apply.admin.notice.item.component';
import { PageReportComponent } from './page.report/page.report.component';
import { ComponentNavUserComponent } from './component.nav.user/component.nav.user.component';
import { PortalKreonetChartTrafficComponent } from './portal.kreonet.chart.traffic/portal.kreonet.chart.traffic.component';
import { PageApplyReportComponent } from './page.apply.report/page.apply.report.component';
import { PortalWorksProjectMeetingComponent } from './portal.works.project.meeting/portal.works.project.meeting.component';
import { PortalKreonetadmDetailComponent } from './portal.kreonetadm.detail/portal.kreonetadm.detail.component';
import { PortalKreonetadmMailComponent } from './portal.kreonetadm.mail/portal.kreonetadm.mail.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalDizestSidebarCodeflowComponent } from './portal.dizest.sidebar.codeflow/portal.dizest.sidebar.codeflow.component';
import { PortalApplyItemComponent } from './portal.apply.item/portal.apply.item.component';
import { PageApplyComponent } from './page.apply/page.apply.component';
import { PortalKreonetadmTicketingComponent } from './portal.kreonetadm.ticketing/portal.kreonetadm.ticketing.component';
import { PortalKreonetWidgetNavComponent } from './portal.kreonet.widget.nav/portal.kreonet.widget.nav.component';
import { ComponentNavUsersComponent } from './component.nav.users/component.nav.users.component';
import { ComponentReplyComponent } from './component.reply/component.reply.component';
import { PortalCommunityEditComponent } from './portal.community.edit/portal.community.edit.component';
import { PageInstitutesEventComponent } from './page.institutes.event/page.institutes.event.component';
import { PortalDizestSidebarWorkflowinfoComponent } from './portal.dizest.sidebar.workflowinfo/portal.dizest.sidebar.workflowinfo.component';
import { PortalKreonetadmInstitutesListComponent } from './portal.kreonetadm.institutes.list/portal.kreonetadm.institutes.list.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { ComponentNavInstituteComponent } from './component.nav.institute/component.nav.institute.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PortalDizestEditorTextComponent } from './portal.dizest.editor.text/portal.dizest.editor.text.component';
import { PortalKreonetadmUsersComponent } from './portal.kreonetadm.users/portal.kreonetadm.users.component';
import { PortalKreonetadmWidgetNavComponent } from './portal.kreonetadm.widget.nav/portal.kreonetadm.widget.nav.component';
import { PageAdminResearchComponent } from './page.admin.research/page.admin.research.component';
import { PortalKreonetadmWidgetInstituteTicketingComponent } from './portal.kreonetadm.widget.institute.ticketing/portal.kreonetadm.widget.institute.ticketing.component';
import { ComponentNavAdminComponent } from './component.nav.admin/component.nav.admin.component';
import { LayoutUserComponent } from './layout.user/layout.user.component';
import { LayoutInstituteComponent } from './layout.institute/layout.institute.component';
import { PortalCommunityListComponent } from './portal.community.list/portal.community.list.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PagePageNoticeItemComponent } from './page.page.notice.item/page.page.notice.item.component';
import { PortalKreonetadmDocComponent } from './portal.kreonetadm.doc/portal.kreonetadm.doc.component';
import { ComponentInstitutesEventDetailComponent } from './component.institutes.event.detail/component.institutes.event.detail.component';
import { PortalWorksMessageBodyComponent } from './portal.works.message.body/portal.works.message.body.component';
import { PortalDizestWidgetWorkflowNodeComponent } from './portal.dizest.widget.workflow.node/portal.dizest.widget.workflow.node.component';
import { PageDashboardComponent } from './page.dashboard/page.dashboard.component';
import { PageAuthenticateComponent } from './page.authenticate/page.authenticate.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalWorksProjectMemberComponent } from './portal.works.project.member/portal.works.project.member.component';
import { PortalDizestEditorWorkflowComponent } from './portal.dizest.editor.workflow/portal.dizest.editor.workflow.component';
import { PageInstitutesItemComponent } from './page.institutes.item/page.institutes.item.component';

const INDEX_PAGE = "/dashboard";

import { URLPattern } from "urlpattern-polyfill";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

let app_routes: Routes = [
    {
        component: LayoutAdminComponent,
        "children": [
            {
                "path": "admin/system/:page/:item?",
                component: PageAdminSystemComponent,
                "app_id": "page.admin.system"
            },
            {
                "path": "admin/info/:page/:item?/:category?/:id?",
                component: PagePageAdminInfoComponent,
                "app_id": "page.page.admin.info"
            },
            {
                "path": "admin/research/:page/:item?",
                component: PageAdminResearchComponent,
                "app_id": "page.admin.research"
            }
        ]
    },
    {
        component: LayoutUserComponent,
        "children": [
            {
                "path": "apply/noti/:id",
                component: PageApplyNotiComponent,
                "app_id": "page.apply.noti"
            },
            {
                "path": "institutes",
                component: PageInstitutesComponent,
                "app_id": "page.institutes"
            },
            {
                "path": "notice",
                component: PageNoticeComponent,
                "app_id": "page.notice"
            },
            {
                "path": "apply/report/:id",
                component: PageApplyReportComponent,
                "app_id": "page.apply.report"
            },
            {
                "path": "apply/:item?",
                component: PageApplyComponent,
                "app_id": "page.apply"
            },
            {
                "path": "notice/:category?/:id",
                component: PagePageNoticeItemComponent,
                "app_id": "page.page.notice.item"
            },
            {
                "path": "dashboard",
                component: PageDashboardComponent,
                "app_id": "page.dashboard"
            }
        ]
    },
    {
        component: LayoutInstituteComponent,
        "children": [
            {
                "path": "institutes/docs/:id",
                component: PageInstitutesDocsComponent,
                "app_id": "page.institutes.docs"
            },
            {
                "path": "institutes/event/:id",
                component: PageInstitutesEventComponent,
                "app_id": "page.institutes.event"
            },
            {
                "path": "institutes/info/:id",
                component: PageInstitutesItemComponent,
                "app_id": "page.institutes.item"
            }
        ]
    },
    {
        component: LayoutLayoutMypageComponent,
        "children": [
            {
                "path": "mydesk/mypage",
                component: PageMydeskMypageComponent,
                "app_id": "page.mydesk.mypage"
            }
        ]
    },
    {
        component: LayoutEmptyComponent,
        "children": [
            {
                "path": "admin/report/:id",
                component: PageReportComponent,
                "app_id": "page.report"
            },
            {
                "path": "authenticate",
                component: PageAuthenticateComponent,
                "app_id": "page.authenticate"
            }
        ]
    }
];

export class RouteInfo {
    public path: any = "";
    public segment: any = {};

    constructor() { }
}

window.WizRoute = new RouteInfo();

let patternMatcher = (pattern: any, url: any) => {
    let urlpath = url.map((x: any) => x.path).join("/");
    let testurl = 'http://test/';
    pattern = '/' + pattern;
    urlpath = testurl + urlpath;
    pattern = new URLPattern({ pathname: pattern });
    pattern = pattern.exec(urlpath)
    if (pattern && pattern.pathname) {
        let posParams = {};
        for (let key in pattern.pathname.groups) {
            if (pattern.pathname.groups[key]) {
                posParams[key] = pattern.pathname.groups[key];
            }
        }
        window.WizRoute.path = url.map((x: any) => x.path).join("/");
        window.WizRoute.segment = posParams;

        return { consumed: url, posParams: posParams };
    }
    return null
}

let routes: Routes = [{
    matcher: (url: any) => {
        for (let i = 0; i < app_routes.length; i++) {
            let layout = app_routes[i];
            let layout_childs = layout.children;
            for (let j = 0; j < layout_childs.length; j++) {
                let child = layout_childs[j];
                let matcher = patternMatcher(child.path, url);
                if (matcher)
                    return null;
            }
        }
        return { consumed: url, posParams: {} };
    },
    redirectTo: INDEX_PAGE
}];

for (let i = 0; i < app_routes.length; i++) {
    let layout = app_routes[i];
    let layout_component = layout.component;
    let layout_childs = layout.children;

    let router: any = {
        path: '',
        component: layout_component,
        children: []
    };

    for (let j = 0; j < layout_childs.length; j++) {
        let child = layout_childs[j];
        router.children.push({
            matcher: (url: any) => {
                let matcher = patternMatcher(child.path, url);
                if (matcher) return matcher;
                return null;
            },
            component: child.component
        });
    }
    routes.push(router);
}

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule { }